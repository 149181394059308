import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
    text-align: center;
  }

  span {
    color: var(--primary);
  }

  p {
    line-height: 1.6em;
  }
`

const organisation_item = () => {
  return (
    <>
      <Seo title="Ciężki dym" />
      <Layout>
        <SimpleBanner title="Ciężki dym">
          <StaticImage
            className="banner__image"
            src="../../images/organisation/dym.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            <b>
              „TANIEC W CHMURACH” - już na stałe zawitał na polskich weselach,
              jako jedna z najbardziej pożądanych atrakcji.
            </b>
          </p>
          <p>
            Proponujemy Państwu wynajęcie profesjonalnej wytwornicy ciężkiego
            dymu, która sprawi że Wasz pierwszy taniec będzie niezapomnianą
            chwilą i przeżyciem zarówno dla Was jak i wszystkich gości.
          </p>
          <p>
            Ciężki dym kłębi się kilkanaście centymetrów nad ziemią sprawiając
            wrażenie, jakby para tańczyła w chmurach!!! Podobny efekt uzyskuje
            się w różnych tanecznych programach np. : Taniec z gwiazdami.
          </p>
          <p>
            Dym jest idealny dla par, które świetnie dają sobie radę na
            parkiecie, jak i również dla tych, którym przychodzi to trudniej.
            Taniec w chmurach przysłoni małe wpadki i ukryje niedoskonałości w
            tańcu, odsłoni natomiast to co najważniejsze, czyli szczęście na
            twarzach Młodej Pary. Fotograf czy kamerzysta będą mieli
            niepowtarzalną okazję zrobienia niebanalnych i niepowtarzalnych
            zdjęć podczas pierwszego tańca, a zaproszeni goście będą oczarowani
            występem.
          </p>
          <p>
            Posiadamy profesjonalny sprzęt, który pozwala uzyskać rewelacyjny i
            zniewalający efekt na sali lub scenie o powierzchni do 200 m2 ( bez
            żadnych przerw wydmuchu). Dym, który nie plami, nie brudzi i przede
            wszystkim nie zalewa parkietu śliskim płynem !!!Usługa jest
            kompleksowa.
          </p>
          <p className="link-container">
            <Link to="/contact">Zapytaj nas o szczegóły</Link>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default organisation_item
